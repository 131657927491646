import React, { useEffect, useState } from 'react';
import { authorizedPost, listAllMealPlans, resetAllMealPlansData, toggleLoading } from '../../store/actions';
import { Box, Button, CircularProgress, MenuItem, Modal, Stack, Typography } from '@mui/material';
import { tableStyle } from '../../components';
import { DataGrid } from '@mui/x-data-grid';
import { ACTIVATE_OTA_RATEPLAN, ADD_NEW_ROOMTYPE_OTA_RATEPLAN, LIST_ALL_OTA_RATEPLANS } from '../../graphql';
import AvailabilityUpdation from '../../components/AvailabilityUpdation';
import { Add } from '@mui/icons-material';
import Header from '../../components/Header';
import { CssTextField } from '../../components/TextField';
import { useSelector } from 'react-redux';
import { allOtas } from '../../data';

function RoomtypeOtaRatePlans({ roomtypeId, dispatch, colors }) {
    const [otaRatePlansList, setOtaRatePlansList] = useState([]);
    const [selectedOta, setSelectedOta] = useState("");
    const [selectedMealPlan, setSelectedMealPlan] = useState("");
    const [rateCode, setRateCode] = useState("");
    const [name, setName] = useState("");
    const [open, setOpen] = useState(false);

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: { sm: 400, xs: "90%" },
        bgcolor: colors.primary[400],
        outline: "none",
        boxShadow: 24,
        p: 2,
    };

    const { isLoading, mealPlans } = useSelector(
        ({
            loading,
            booking: { mealPlans: { list } }
        }) => ({
            isLoading: loading,
            mealPlans: list,
        })
    );

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, [])

    const fetchData = async () => {
        dispatch(toggleLoading(true));
        let response = await authorizedPost(LIST_ALL_OTA_RATEPLANS, { roomtypeId: roomtypeId })
        if (response?.data) {
            setOtaRatePlansList(response.data.list_all_ota_rateplans.data);
        }
        
        dispatch(resetAllMealPlansData());
        dispatch(listAllMealPlans({ 
            roomtypeId: roomtypeId
        }));
        dispatch(toggleLoading(false));
    }

    const handleSubmit = async(e) => {
        e.preventDefault();
        dispatch(toggleLoading(true));
        let data = {
            id: roomtypeId,
            ota: selectedOta,
            rateCode: rateCode,
            name: name,
            mealPlanId: selectedMealPlan,
        }
        console.log(data);
        await authorizedPost(ADD_NEW_ROOMTYPE_OTA_RATEPLAN, data);
        await fetchData();
        setSelectedOta("");
        setRateCode("");
        setName("");
        setSelectedMealPlan("");
        setOpen(false);
        dispatch(toggleLoading(false));
    }

    const columns = [
        {
            field: "ota",
            headerName: "Ota name",
            cellClassName: "name-column--cell",
            width: 200,
        },
        { field: "rate_code", headerName: "Rate code", width: 200 },
        {
            field: "meal_plan_id",
            headerName: "Meal plan",
            width: 150,
            renderCell: ({ row: { meal_plan_id } }) => meal_plan_id?.name ?? "-",
        },
        {
            field: "is_active",
            headerName: "Availability",
            width: 150,
            renderCell: ({ row: { is_active, _id } }) => {
                return (
                    <AvailabilityUpdation
                        isActive={is_active}
                        action={async () => {
                            await authorizedPost(ACTIVATE_OTA_RATEPLAN, { id: _id })
                            fetchData()
                        }}
                    />
                );
            },
        },
    ];

    return (
        <>
            <Box mb={15}>
                <Box
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                    alignItems={"end"}
                    m="20px 0"
                >
                    <Typography variant='h4' fontWeight="bold">
                        OTA RATE PLANS
                    </Typography>
                    <Box>
                        <Button
                            sx={{
                                backgroundColor: colors.blueAccent[700],
                                color: colors.grey[100],
                                fontSize: "14px",
                                fontWeight: "bold",
                                padding: { sm: "10px 20px", xs: "5px 10px" },
                            }}
                            onClick={() => setOpen(true)}
                        >
                            <Add sx={{ mr: "10px" }} />
                            Add new rate plan
                        </Button>
                    </Box>
                </Box>
                <Box
                    m="8px 0 0 0"
                    height="350px"
                    sx={tableStyle(colors)}
                >
                    <DataGrid
                        rows={otaRatePlansList}
                        columns={columns}
                        getRowId={(row) => row?._id}
                    />
                </Box>

                <Modal
                    open={open}
                    onClose={() => setOpen(false)}
                >
                    <Box sx={modalStyle}>
                        <Header title="ADD NEW RATE PLAN" titleVariant="h4" />
                        <form onSubmit={handleSubmit}>
                            <Stack spacing={2}>
                                <CssTextField
                                    fullWidth
                                    required
                                    select
                                    size="small"
                                    label="OTA"
                                    value={selectedOta ?? ""}
                                    onChange={(e) => setSelectedOta(e.target.value)}
                                >
                                    {allOtas?.map((ota, i) => (
                                        <MenuItem value={ota} key={i}>
                                            {ota}
                                        </MenuItem>
                                    ))}
                                </CssTextField>
                                <CssTextField
                                    fullWidth
                                    required
                                    size="small"
                                    type="text"
                                    label="Rate code"
                                    placeholder="Rate code"
                                    value={rateCode ?? ""}
                                    onChange={(e) => setRateCode(e.target.value?.replace(" ", ""))}
                                />
                                 <CssTextField
                                    fullWidth
                                    size="small"
                                    type="text"
                                    label="Rate plan name"
                                    placeholder="Rate plan name"
                                    value={name ?? ""}
                                    onChange={(e) => setName(e.target.value)}
                                />
                                <CssTextField
                                    fullWidth
                                    required
                                    select
                                    size="small"
                                    label="Meal plan"
                                    value={selectedMealPlan ?? ""}
                                    onChange={(e) => setSelectedMealPlan(e.target.value)}
                                >
                                    {mealPlans?.map((e, i) => (
                                        <MenuItem value={e._id} key={i} disabled={!e.is_active}>
                                            {e.name}
                                        </MenuItem>
                                    ))}
                                </CssTextField>
                                <Button
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    color="secondary"
                                    variant="contained"
                                    sx={{ fontSize: "15px" }}
                                >
                                    {isLoading ? <CircularProgress color="inherit" size={24} /> : <b>Submit</b>}
                                </Button>
                            </Stack>
                        </form>
                    </Box>
                </Modal>
            </Box>
        </>
    )
}

export default RoomtypeOtaRatePlans;