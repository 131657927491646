// /*eslint-disable*/
import React, { useEffect, useRef, useState } from 'react';
import { activateRoomtypeOtaCount, addRoomtypeOtaCount, authorizedPost, listAllRoomtypeOtaCount, resetAllRoomtypeOtaCount, syncRoomtypeOtaCount } from '../../store/actions';
import { useSelector } from 'react-redux';
import { Box, Button, Checkbox, CircularProgress, InputAdornment, MenuItem, Modal, Stack, Typography } from '@mui/material';
import { CssTextField } from '../../components/TextField';
import { DataGrid, GridFooterContainer } from '@mui/x-data-grid';
import { tableStyle } from '../../components';
import { Add, CalendarMonth, Sync } from '@mui/icons-material';
import Header from '../../components/Header';
import moment from 'moment';
import AvailabilityUpdation from '../../components/AvailabilityUpdation';
import ButtonWithConfirmModal from '../../components/ButtonWithConfirmModal';
import { LIST_ALL_ROOMTYPE_OTAS } from '../../graphql';

const startIcon = {
    startAdornment: (
        <InputAdornment position="start">
            <CalendarMonth fontSize='small' sx={{ cursor: "pointer" }} />
        </InputAdornment>
    ),
}

function RoomtypeOtaCount({ roomtypeId, dispatch, colors }) {
    const initialValues = {
        startDate: moment().format("YYYY-MM-DD"),
        endDate: moment().add(1, "days").format("YYYY-MM-DD"),
        count: "",
    }
    const startInputRef = useRef();
    const endInputRef = useRef();
    const [values, setValues] = useState(initialValues);
    const [otaList, setOtaList] = useState([]);
    const [selectedOtas, setSelectedOtas] = useState([]);
    // eslint-disable-next-line
    const [temp, setTemp] = useState("");
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: { sm: 400, xs: "90%" },
        bgcolor: colors.primary[400],
        outline: "none",
        boxShadow: 24,
        p: 2,
    };

    const { isLoading, countsList, showViewMore } = useSelector(
        ({
            loading,
            roomtypes: { otaCounts: { list, showViewMore } }
        }) => ({
            isLoading: loading,
            countsList: list,
            showViewMore
        })
    );

    useEffect(() => {
        dispatch(resetAllRoomtypeOtaCount());
        fetchMoreData();
        fetchRoomtypeOtaList();
        // eslint-disable-next-line
    }, [])

    const fetchMoreData = () => {
        dispatch(listAllRoomtypeOtaCount({
            roomtypeId: roomtypeId
        }));
    }

    const fetchRoomtypeOtaList = async() => {
        // dispatch(toggleLoading(true));
        let response = await authorizedPost(LIST_ALL_ROOMTYPE_OTAS, {roomtypeId: roomtypeId })
        if(response?.data){
            setOtaList(response.data.list_all_roomtype_otas.data);
        }
        // dispatch(toggleLoading(false));
    }

    const CustomFooter = () => {
        return (
            <GridFooterContainer>
                <Stack width="100%" alignItems="center">
                    {showViewMore ? <Button fullWidth onClick={fetchMoreData}>
                        <b>view more</b>
                    </Button> : <Typography variant="h6">All datas listed</Typography>}
                </Stack>
            </GridFooterContainer>
        );
    }

    const columns = [
        {
            field: "start_date",
            headerName: "Start date",
            width: 150,
            cellClassName: "name-column--cell",
            valueGetter: (params) => `${params.row.start_date ? moment.unix(params.row.start_date).format("DD-MM-YYYY") : "-"}`,
        },
        {
            field: "end_date",
            headerName: "End date",
            width: 150,
            cellClassName: "name-column--cell",
            valueGetter: (params) => `${params.row.end_date ? moment.unix(params.row.end_date).format("DD-MM-YYYY") : "-"}`,
        },
        {
            field: "otas",
            headerName: "OTAs",
            width: 150,
            renderCell: ({ row: { otas } }) => {
                if (otas?.length) {
                    return (
                        <>
                            <CssTextField
                                fullWidth
                                select
                                size="small"
                                // label="Weekdays"
                                value={otas}
                                SelectProps={{ multiple: true }}
                            >
                                {otas?.map((day, i) => (
                                    <MenuItem value={day} key={i}>
                                        <Checkbox sx={{ p: "0 5px" }} checked={otas.indexOf(day) > -1} />
                                        {day}
                                    </MenuItem>
                                ))}
                            </CssTextField>
                        </>
                    );
                } else {
                    return "-";
                }
            },
        },
        { field: "count", headerName: "Count", width: 150 },
        {
            field: "is_active",
            headerName: "Availability",
            width: 120,
            renderCell: ({ row: { is_active, _id } }) => {
                return (
                    <AvailabilityUpdation
                        isActive={is_active}
                        action={() => dispatch(activateRoomtypeOtaCount({ id: _id, roomtypeId: roomtypeId }))}
                    />
                );
            },
        },
        {
            field: "_id",
            headerName: "Sync",
            width: 130,
            renderCell: ({ row: { _id } }) => {
                return (
                    <ButtonWithConfirmModal
                        BtnLabel="Sync"
                        text="Are you sure to sync ?"
                        action={() => dispatch(syncRoomtypeOtaCount({ id: _id, roomtypeId: roomtypeId }))}
                        btnIcon={<Sync />}
                    />
                );
            },
        },
        {
            field: "last_sync",
            headerName: "Last sync",
            width: 180,
            renderCell: ({ row: { last_sync } }) => last_sync ? moment.unix(last_sync).format("DD-MMM-YYYY - HH:mm") : "-",
        },
        {
            field: "sync_status",
            headerName: "Sync status",
            width: 120,
            renderCell: ({ row: { sync_status } }) => sync_status ? sync_status : "-",
        },
    ];

    const handleChange = (event) => {
        let fieldname = event?.target?.name;
        let data = values;
        data[fieldname] = event?.target?.value;
        setTemp(event.target.value);
        setValues(data);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let data = {
            // ...values,
            startDate: moment(values.startDate, "YYYY-MM-DD").unix(),
            endDate: moment(values.endDate, "YYYY-MM-DD").unix(),
            count: parseInt(values.count),
            otas: selectedOtas,
            roomtypeId,
        }
        console.log(data);
        dispatch(addRoomtypeOtaCount(data))
        handleClose();
    }

    return (
        <Box mb={15}>
            <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                alignItems={"end"}
                m="20px 0"
            >
                <Typography variant='h4' fontWeight="bold">
                    OTA COUNTS
                </Typography>
                <Box>
                    <Button
                        sx={{
                            backgroundColor: colors.blueAccent[700],
                            color: colors.grey[100],
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: { sm: "10px 20px", xs: "5px 10px" },
                        }}
                        onClick={handleOpen}
                    >
                        <Add sx={{ mr: "10px" }} />
                        Add new count
                    </Button>
                </Box>
            </Box>
            <Box
                m="8px 0 0 0"
                height="350px"
                sx={tableStyle(colors)}
            >
                <DataGrid
                    rows={countsList}
                    columns={columns}
                    components={{ Footer: CustomFooter }}
                    getRowId={(row) => row?._id}
                />
            </Box>

            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={modalStyle}>
                    <Header title="ADD NEW COUNT" titleVariant="h4" />
                    <form onSubmit={handleSubmit}>
                        <Stack spacing={2}>
                            <CssTextField
                                fullWidth
                                required
                                size="small"
                                type="date"
                                label="start date"
                                placeholder="Start date"
                                name="startDate"
                                value={moment(values.startDate).format("YYYY-MM-DD")}
                                inputProps={{ min: moment().format("YYYY-MM-DD"), max: moment().add(720, "days").format("YYYY-MM-DD") }}
                                onChange={handleChange}
                                inputRef={startInputRef}
                                onClick={() => startInputRef.current.showPicker()}
                                InputProps={startIcon}
                            />
                            <CssTextField
                                fullWidth
                                required
                                size="small"
                                type="date"
                                label="End date"
                                placeholder="End date"
                                name="endDate"
                                value={moment(values.endDate).format("YYYY-MM-DD")}
                                inputProps={{ min: moment(values.startDate).format("YYYY-MM-DD"), max: moment().add(720, "days").format("YYYY-MM-DD") }}
                                onChange={handleChange}
                                inputRef={endInputRef}
                                onClick={() => endInputRef.current.showPicker()}
                                InputProps={startIcon}
                            />
                            <CssTextField
                                fullWidth
                                select
                                size="small"
                                label="OTAs"
                                value={selectedOtas}
                                SelectProps={{ multiple: true }}
                                onChange={(e) => setSelectedOtas(e.target.value)}
                            >
                                {otaList?.map((e, i) => (
                                    <MenuItem value={e.ota} key={i} disabled={!e?.is_active}>
                                        <Checkbox sx={{ p: "0 5px" }} checked={selectedOtas.indexOf(e.ota) > -1} />
                                        {e.ota}
                                    </MenuItem>
                                ))}
                                {!otaList?.length ? (
                                    <MenuItem value="" disabled>
                                        No OTAs available
                                    </MenuItem>
                                ) : null}
                            </CssTextField>
                            <CssTextField
                                fullWidth
                                required
                                size="small"
                                type="number"
                                label="Count"
                                placeholder="Count"
                                name="count"
                                value={values?.count ?? ""}
                                onChange={handleChange}
                            />
                            <Button
                                fullWidth
                                size="large"
                                type="submit"
                                color="secondary"
                                variant="contained"
                                sx={{ fontSize: "15px" }}
                            >
                                {isLoading ? <CircularProgress color="inherit" size={24} /> : <b>Submit</b>}
                            </Button>
                        </Stack>
                    </form>
                </Box>
            </Modal>
        </Box>
    )
}

export default RoomtypeOtaCount;