import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ColorModeContext, tokens } from "../../theme";
import { useProSidebar } from "react-pro-sidebar";
import { authorizedPost, getRegistrationStatus, listHotelProfile, logoutUser, toggleLoading } from "../../store/actions";
import { useNavigate } from "react-router-dom";
import { useTheme, Box, IconButton, Menu, MenuItem, Typography, ListItemIcon, ListItemText } from "@mui/material";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { AccountBox, Apartment, ExpandLess, ExpandMore, Home, Logout } from "@mui/icons-material";
import { GENERATE_HOTEL_TOKEN, LIST_ALL_STAFF_HOTELS } from "../../graphql";
import { LOGIN_SUCCESS } from "../../store/types";
import ConfirmModal from "../../components/ConfirmModal";

const Topbar = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const colorMode = useContext(ColorModeContext);
    const { toggleSidebar, broken, rtl } = useProSidebar();
    const [anchorElUser, setAnchorElUser] = useState(null);
    const [regStatus, setRegStatus] = useState(false);
    const userActions = [
        { title: "Dashboard", icon: <Home />, action: () => navigate("/dashboard"), disabled: !regStatus ? true : false },
        { title: "Profile & settings", icon: <AccountBox />, action: () => navigate("/profile"), disabled: !regStatus ? true : false },
        {
            title: "Logout", 
            icon: <Logout />,
            action: () => {
                localStorage.removeItem("dateNum");
                localStorage.removeItem("calType");
                localStorage.removeItem("expanded");
                dispatch(logoutUser())
            }, 
            disabled: false
        },
    ];
    const [staffHotels, setStaffHotels] = useState([]);
    const [modal, setModal] = useState(false);
    const [hotelId, setHotelId] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClose = () => setAnchorEl(null);

    const { hotelProfile } = useSelector(({ registration: { hotelProfile } }) => ({
        hotelProfile,
    }));

    useEffect(() => {
        fetchStatus();
        fetchStaffHotels();
        dispatch(listHotelProfile());
        // eslint-disable-next-line
    }, [dispatch]);

    const fetchStatus = async () => {
        let reg = await dispatch(getRegistrationStatus());
        setRegStatus(reg);
    }

    const fetchStaffHotels = async () => {
        let res = await authorizedPost(LIST_ALL_STAFF_HOTELS);
        if (res.status) {
            setStaffHotels(res?.data?.list_all_staff_hotels?.data)
        }
    }

    const generateHotelToken = async (id) => {
        if (localStorage.getItem("hotelId") !== id) {
            dispatch(toggleLoading(true));
            let res = await authorizedPost(GENERATE_HOTEL_TOKEN, { hotelId: id });
            if (res.status) {
                let { generate_hotel_token: { token } } = res.data;
                dispatch(logoutUser());
                dispatch({
                    type: LOGIN_SUCCESS,
                    payload: token,
                });
            }
            setTimeout(() => {
                dispatch(toggleLoading(false))
                navigate("/dashboard")
                navigate(0)
            }, 1000);
        }
        handleClose();
    }

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
        <Box display="flex" justifyContent="space-between" p={2}>
            <Box display="flex">
                {broken && !rtl && (
                    <IconButton sx={{ margin: "0 6 0 2" }} onClick={() => toggleSidebar()}>
                        <MenuOutlinedIcon />
                    </IconButton>
                )}
                {hotelProfile?.name ? (
                    <Box
                        pl={1}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        maxWidth={{ xs: 150, sm: 400 }}
                        backgroundColor={colors.primary[400]}
                        borderRadius={10}
                    >
                        <Apartment color="primary" />
                        <Typography ml={1} mr={1} noWrap fontWeight="bold" textTransform="capitalize">
                            {hotelProfile.name}
                        </Typography>
                        <IconButton onClick={(event) => setAnchorEl(event.currentTarget)}>
                            {anchorEl ? <ExpandLess /> : <ExpandMore />}
                        </IconButton>
                    </Box>
                ) : null}
                {/* <Box display="flex" backgroundColor={colors.primary[400]} p={0.2} borderRadius={1}>
                    <InputBase sx={{ ml: 1, flex: 1 }} placeholder="Search" />
                    <IconButton type="button">
                        <SearchIcon />
                    </IconButton>
                </Box> */}
            </Box>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                {staffHotels?.map((el, idx) => (
                    <MenuItem
                        key={idx}
                        selected={localStorage.getItem("hotelId") === el._id}
                        onClick={() => {
                            if (localStorage.getItem("hotelId") === el._id) {
                                handleClose();
                            } else {
                                setHotelId(el._id);
                                setModal(true);
                            }
                        }}
                    >
                        {el.name}
                    </MenuItem>
                ))}
                {!staffHotels?.length ? (
                    <MenuItem
                        selected
                        onClick={handleClose}
                    >
                        {localStorage.getItem("hotelName")}
                    </MenuItem>
                ) : null}
            </Menu>
            <ConfirmModal 
                text="Are you sure to switch account?" 
                open={modal} 
                setModal={setModal}
                action={() => generateHotelToken(hotelId)}
            />
            <Box display="flex">
                <IconButton onClick={colorMode.toggleColorMode}>
                    {theme.palette.mode === "dark" ? (
                        <LightModeOutlinedIcon />
                    ) : (
                        <DarkModeOutlinedIcon />
                    )}
                </IconButton>
                <IconButton>
                    <NotificationsOutlinedIcon />
                </IconButton>
                <IconButton>
                    <SettingsOutlinedIcon />
                </IconButton>
                <IconButton onClick={handleOpenUserMenu}>
                    <PersonOutlinedIcon />
                </IconButton>
                <Menu
                    sx={{ mt: "45px" }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                >
                    {userActions.map((element) => (
                        <Box key={element.title} onClick={!element.disabled ? element.action : null}>
                            <MenuItem onClick={handleCloseUserMenu} disabled={element.disabled}>
                                <ListItemIcon>
                                    {element.icon}
                                </ListItemIcon>
                                <ListItemText>
                                    {element.title}
                                </ListItemText>
                            </MenuItem>
                        </Box>
                    ))}
                </Menu>

                {broken && rtl && (
                    <IconButton sx={{ margin: "0 6 0 2" }} onClick={() => toggleSidebar()}>
                        <MenuOutlinedIcon />
                    </IconButton>
                )}
            </Box>
        </Box>
    );
};

export default Topbar;
