
import React, { useEffect, useState } from "react";
import { 
    Box, CircularProgress, Grid, InputAdornment, 
    MenuItem, Stack, Switch, Typography, useTheme, Button, Modal 
} from "@mui/material";
import { 
    addNewGroupBooking, getSubAgentsForDropdown, listAllHotelAgents, listAllMealPlans, resetAllHotelAgents, resetAllMealPlansData 
} from "../../../store/actions";
import { Add, ArrowBack, Build, Done, Email, ExpandLess, ExpandMore, Money, Person, Phone } from "@mui/icons-material";
import { useSelector, useDispatch } from "react-redux";
import { CssTextField } from "../../../components/TextField";
import { tokens } from "../../../theme";
import { Formik } from "formik";
import { toast } from "react-toastify";
import AddNewAgentModalContent from "../../agents/addNewAgentModalContent";
import AddNewSubAgentModalContent from "../../agents/addNewSubAgentModalContent";
// import Header from "../../../components/Header";
import titles from "../../../data/nameTitles";
import * as yup from "yup";
import SearchAndSelectInput from "../../../components/SearchAndSelect";
// import BookingsSummary from "../../../components/bookingsSummary";
import PlusOrMinusButton from "../../../components/PlusOrMinusButton";

const AddNewBooking = ({ setPage, checkingdata, selectedRooms, navigate }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const dispatch = useDispatch();
    const [status, setStatus] = useState("confirmed");
    // const [mealPlan, setMealPlan] = useState("no_preference");
    const [agent, setAgent] = useState("");
    const [subAgent, setSubAgent] = useState("");
    const [agentEnabled, setAgentEnabled] = useState(false);
    const [extraBedsEnabled, setExtraBedsEnabled] = useState(false);
    const [addAgentModal, setAddAgentModal] = useState(false);
    const [addSubAgentModal, setAddSubAgentModal] = useState(false);
    const [showMore, setShowMore] = useState(false);
    // const rateErrorMsg = `Value should not exceed total rate of selected room (${selectedRooms[0]?.rate})`
    
    const { isLoading, agentsList, subAgentsList, availableRoomsData } = useSelector(
        ({
            loading,
            agents: {
                agents: { list },
                subAgentsList,
            },
            admin: {
                availableRooms,
            },
            // booking: { mealPlans }
        }) => ({
            isLoading: loading,
            agentsList: list,
            subAgentsList,
            availableRoomsData: availableRooms,
            // mealPlans
        })
    );
    let filtered = availableRoomsData?.list[0]?.data.filter((e) => selectedRooms.map((j) => j.room_id).includes(e?._id) )
    let rackRate = filtered.reduce((k,l) => k+l.rate, 0);

    useEffect(() => {
        dispatch(resetAllHotelAgents());
        dispatch(listAllHotelAgents({isForDropdown: true}));
        dispatch(resetAllMealPlansData());
        dispatch(listAllMealPlans({ 
            roomtypeId: selectedRooms[0]?.roomtype_id?._id ,
            isForDropdown: true, 
        }));
        // eslint-disable-next-line
    },[])

    const handleAgentChange = (agent_id) => {
        setAgent(agent_id)
        setSubAgent("")
    }
    const fetchSubAgents = async () => {
        if(agent !== ""){
            dispatch(getSubAgentsForDropdown({ id: agent }));
        }
    }
    useEffect(() => {
        fetchSubAgents()
    // eslint-disable-next-line
    },[agent])

    const handleFormSubmit = async (values) => {
        if(agentEnabled && agent === ""){
            return toast.error("Please select an agent");
        }
        // else if(agentEnabled && agent !== ""){
        //     if(subAgent === ""){
        //         return toast.error("Please select a sub agent");
        //     } else {
        //         let filtered = subAgentsList?.filter((e) => e._id === subAgent)
        //         if(!filtered?.length){
        //             return toast.error("Please select a valid sub agent");
        //         }
        //     }
        // }

        if(!values.customPrice) {
            let filtered = availableRoomsData?.list[0].data.filter((e) => selectedRooms.map((j) => j.room_id).includes(e?._id) )

            let totalAmount = filtered.reduce((k,l) => k+l.rate, 0);
            if(values.paidAmount > totalAmount) {
                return toast.error("Paid amount cannot be higher than total rooms amount");
            }
        }

        // let roomsData = [{
        //     // ...checkingdata.rooms[0],
        //     room_number: 1,
        //     room_id: selectedRooms[0]?.room_id,
        //     no_of_adults: checkingdata.rooms[0]?.no_of_adults,
        //     no_of_childs: checkingdata.rooms[0]?.no_of_childs,
        // }]
        let data = {
            ...values,
            ...checkingdata,
            numOfAdults: checkingdata.rooms[0]?.no_of_adults,
            numOfChilds: checkingdata.rooms[0]?.no_of_childs,
            guestName: values.name,
            guestEmail: values.email,
            status: status,
            // mealPlanId: mealPlan === "no_preference" ? "" : mealPlan,
            agentId: agent === "no_preference" ? "" : agent,
            subAgentId: subAgent === "no_preference" ? "" : subAgent,
            rooms: selectedRooms,
        };
        if (data.paidAmount === ""){
            delete data.paidAmount;
        }
        if (data.customPrice === ""){
            delete data.customPrice;
        }
        if (!extraBedsEnabled) {
            delete data.extraBeds;
        }
        console.log(data);
        let result = await dispatch(addNewGroupBooking(data));
        if (result) {
            navigate("/bookings?tab=recent");
        }
    };
    const initialValues = {
        title: "Mr",
        name: "",
        email: "",
        paidAmount: "",
        customPrice: "",
        preference: "",
        billingInstruction: "",
        // paymentMethod: "",
        guestMobile: "",
        guestAddress: "",
        remarks: "",
        extraBeds: 1,
    };
    const checkoutSchema = yup.object().shape({
        name: yup.string().required("Required"),
        email: yup.string().email("Invalid email"),
        // paidAmount: yup.number().min(0).max(selectedRooms[0]?.rate, rateErrorMsg),
    });

    return (
        <Box 
            width="100%"
            p={2}
            backgroundColor={colors.primary[400]}
        >
            <Grid container>
                <Grid item lg={12} xs={12}>
                    {/* <BookingsSummary
                        checkin={checkingdata?.checkinDate}
                        checkout={checkingdata?.checkoutDate}
                        numOfAdults={checkingdata?.rooms[0]?.no_of_adults}
                        numOfchildren={checkingdata?.rooms[0]?.no_of_childs}
                        roomsData={filtered}
                    /> */}
                    {/* <Stack
                        direction="row"
                        alignItems="flex-end"
                        spacing={1}
                        mb="10px"
                    >
                        <Box title="go back" height={"40px"}>
                            <IconButton type="button" onClick={() => setPage(2)}>
                                <ArrowBack />
                            </IconButton>
                        </Box>
                        <Header title="ENTER BOOKING DETAILS" titleVariant="h4" />
                    </Stack> */}
                    <Typography 
                        mb={1}
                        textAlign="center"
                        fontWeight={600}
                        fontSize={18}
                    >
                        ENTER BOOKING DETAILS
                    </Typography>

                    <Box>
                        <Formik onSubmit={handleFormSubmit} initialValues={initialValues} validationSchema={checkoutSchema}>
                            {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                                <form onSubmit={handleSubmit}>
                                    <Grid container spacing={3}>
                                        <Grid item md={6} xs={12}>
                                            <Stack spacing={2}>
                                                <Stack direction="row">
                                                    <CssTextField
                                                        select
                                                        required
                                                        name="title"
                                                        onChange={handleChange}
                                                        value={values.title}
                                                        sx={{ width: 100 }}
                                                    >
                                                        {titles?.map((title, index) => (
                                                            <MenuItem value={title} key={index}>
                                                                {title}
                                                            </MenuItem>
                                                        ))}
                                                    </CssTextField>
                                                    <CssTextField
                                                        required
                                                        fullWidth
                                                        type="text"
                                                        // label="Name"
                                                        placeholder="Name *"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.name}
                                                        name="name"
                                                        error={!!touched.name && !!errors.name}
                                                        helperText={touched.name && errors.name}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <Person />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </Stack>
                                                <CssTextField
                                                    // required={!agentEnabled}
                                                    fullWidth
                                                    type="email"
                                                    // label="Email"
                                                    placeholder={`Email`}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.email}
                                                    name="email"
                                                    error={!!touched.email && !!errors.email}
                                                    helperText={touched.email && errors.email}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <Email />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                                <CssTextField
                                                    // required={!agentEnabled}
                                                    fullWidth
                                                    type="number"
                                                    placeholder={`Mobile`}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.guestMobile}
                                                    name="guestMobile"
                                                    error={!!touched.guestMobile && !!errors.guestMobile}
                                                    helperText={touched.guestMobile && errors.guestMobile}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <Phone />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Stack>
                                        </Grid>
                                        {showMore ? (
                                        <Grid item md={6} xs={12}>
                                            <Stack spacing={2}>
                                                <CssTextField
                                                    fullWidth
                                                    type="text"
                                                    label="Preference"
                                                    placeholder="Preference"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.preference}
                                                    name="preference"
                                                    error={!!touched.preference && !!errors.preference}
                                                    helperText={touched.preference && errors.preference}
                                                />
                                                <CssTextField
                                                    fullWidth
                                                    type="text"
                                                    label="Billing Instruction"
                                                    placeholder="Billing Instruction"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.billingInstruction}
                                                    name="billingInstruction"
                                                    error={!!touched.billingInstruction && !!errors.billingInstruction}
                                                    helperText={touched.billingInstruction && errors.billingInstruction}
                                                />
                                                <CssTextField
                                                    fullWidth
                                                    multiline
                                                    minRows={3}
                                                    maxRows={Infinity}
                                                    type="text"
                                                    size="small"
                                                    label="Address"
                                                    placeholder="Address"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.guestAddress}
                                                    name="guestAddress"
                                                    error={!!touched.guestAddress && !!errors.guestAddress}
                                                    helperText={touched.guestAddress && errors.guestAddress}
                                                />
                                                <CssTextField
                                                    fullWidth
                                                    multiline
                                                    minRows={3}
                                                    maxRows={Infinity}
                                                    type="text"
                                                    size="small"
                                                    label="Remarks"
                                                    placeholder="Remarks"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.remarks}
                                                    name="remarks"
                                                    error={!!touched.remarks && !!errors.remarks}
                                                    helperText={touched.remarks && errors.remarks}
                                                />
                                                <Stack direction="row" alignItems="center">
                                                    <Typography noWrap>Agent booking</Typography>
                                                    <Switch
                                                        checked={agentEnabled}
                                                        onChange={(e) => setAgentEnabled(e.target.checked)}
                                                    />
                                                </Stack>
                                                {agentEnabled && (
                                                    <>
                                                        <Stack direction="row" spacing={1}>
                                                            <SearchAndSelectInput
                                                                size="large"
                                                                label="Agent / Agency"
                                                                keyname="first_name"
                                                                value={agent}
                                                                required={agentEnabled}
                                                                list={agentsList}
                                                                onChange={(id) => handleAgentChange(id)}
                                                            />
                                                            <Button
                                                                variant="outlined"
                                                                color="primary"
                                                                title="Add new agent"
                                                                onClick={() => setAddAgentModal(true)}
                                                            >
                                                                <Add />
                                                            </Button>
                                                        </Stack>
                                                        <Stack direction="row" spacing={1}>
                                                            <SearchAndSelectInput
                                                                size="large"
                                                                label="Sub Agent"
                                                                keyname="name"
                                                                value={subAgent}
                                                                // required={agentEnabled}
                                                                list={subAgentsList}
                                                                disabled={!agent || agent === ""}
                                                                onChange={(id) => setSubAgent(id)}
                                                            />
                                                            <Button
                                                                variant="outlined"
                                                                color="primary"
                                                                title="Add new sub agent"
                                                                onClick={() => setAddSubAgentModal(true)}
                                                                disabled={!agent || agent === ""}
                                                            >
                                                                <Add />
                                                            </Button>
                                                        </Stack>
                                                    </>
                                                )}
                                                <Stack direction="row" alignItems="center">
                                                    <Typography noWrap>Extra beds</Typography>
                                                    <Switch
                                                        checked={extraBedsEnabled}
                                                        onChange={(e) => setExtraBedsEnabled(e.target.checked)}
                                                    />
                                                </Stack>
                                                {extraBedsEnabled && (
                                                    <PlusOrMinusButton
                                                        min={1}
                                                        max={10}
                                                        size="large"
                                                        label="Number of extra beds"
                                                        name="extraBeds"
                                                        value={values.extraBeds}
                                                        handleChange={(value) => setFieldValue("extraBeds", value)}
                                                    />
                                                )}
                                            </Stack>
                                        </Grid>
                                        ) : null}
                                        <Grid item md={6} xs={12} mt={showMore ? errors.name ? -28 : -31 : ""}>
                                            <Stack spacing={2}>
                                                <CssTextField
                                                    fullWidth
                                                    select
                                                    value={status}
                                                    label="Status"
                                                    onChange={(e) => setStatus(e.target.value)}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <Build />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                >
                                                    <MenuItem value={"confirmed"}>
                                                        {"Confirmed"}
                                                    </MenuItem>
                                                    <MenuItem value={"hold"}>
                                                        {"Block"}
                                                    </MenuItem>
                                                </CssTextField>
                                                {/* <CssTextField
                                                    fullWidth
                                                    select
                                                    value={mealPlan}
                                                    label="Meal Plan"
                                                    onChange={(e) => setMealPlan(e.target.value)}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <Restaurant />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                >
                                                    <MenuItem value={"no_preference"}>
                                                        {"Room Only"}
                                                    </MenuItem>
                                                    {mealPlans?.list?.map((e) => (
                                                        <MenuItem value={e?._id} key={e?._id}>
                                                            <Stack
                                                                width="100%"
                                                                direction="row"
                                                                justifyContent="space-between"
                                                                spacing={2}
                                                            >
                                                                <Typography>
                                                                    {e?.name?.toUpperCase()}
                                                                </Typography>
                                                                <Typography>
                                                                    ₹{e?.rate}
                                                                </Typography>
                                                            </Stack>
                                                        </MenuItem>
                                                    ))}
                                                </CssTextField> */}
                                                <CssTextField
                                                    fullWidth
                                                    type="number"
                                                    label="Paid Amount"
                                                    placeholder="Paid Amount"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.paidAmount}
                                                    name="paidAmount"
                                                    // onInvalid={e => e.target.setCustomValidity(rateErrorMsg)}
                                                    // onInput={e => e.target.setCustomValidity('')}
                                                    error={!!touched.paidAmount && !!errors.paidAmount}
                                                    helperText={touched.paidAmount && errors.paidAmount}
                                                    InputProps={{
                                                        inputProps: { min: 0, max: values.customPrice ?? rackRate },
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <Money />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                                <CssTextField
                                                    fullWidth
                                                    type="number"
                                                    label="Special Price"
                                                    placeholder="Special Price"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.customPrice}
                                                    name="customPrice"
                                                    // error={!!touched.customPrice && !!errors.customPrice}
                                                    helperText={`Rack rate: ${rackRate}`}
                                                    InputProps={{
                                                        inputProps: { min: 0 },
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <Money />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Stack>
                                        </Grid>
                                        <Grid item md={12} xs={12}>
                                            <Button 
                                                size="small"
                                                endIcon={showMore ? <ExpandLess /> : <ExpandMore />}
                                                onClick={() => setShowMore(showMore ? false : true)}
                                            >
                                                show {showMore ? "less" : "more"}
                                            </Button>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={2} mt={1}>
                                        <Grid item md={12} xs={12}>
                                            <Stack direction="row" justifyContent="space-between">
                                                <Button
                                                    variant="outlined"
                                                    startIcon={<ArrowBack />}
                                                    onClick={() => setPage(2)}
                                                >
                                                    <b>Go back</b>
                                                </Button>
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    endIcon={<Done />}
                                                >
                                                    {isLoading ? <CircularProgress color="inherit" size={24} /> : <b>Book Now</b>}
                                                </Button>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Formik>
                    </Box>
                </Grid>
            </Grid>

            <Modal
                open={addAgentModal}
                onClose={() => setAddAgentModal(false)}
            >
                <>
                    <AddNewAgentModalContent
                        dispatch={dispatch}
                        isLoading={isLoading}
                        setModal={setAddAgentModal}
                        setAgent={setAgent}
                        colors={colors}
                    />
                </>
            </Modal>

            <Modal
                open={addSubAgentModal}
                onClose={() => setAddSubAgentModal(false)}
            >
                <>
                    <AddNewSubAgentModalContent
                        id={agent}
                        dispatch={dispatch}
                        isLoading={isLoading}
                        setModal={setAddSubAgentModal}
                        setSubAgent={setSubAgent}
                        colors={colors}
                    />
                </>
            </Modal>
        </Box >
    );
};

export default AddNewBooking;