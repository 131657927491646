import { post } from "../../services";
import {
    ACTIVATE_ROOMTYPE,
    ADD_NEW_FACILITY,
    ADD_NEW_HIGHLIGHT,
    ADD_NEW_RATE,
    ADD_NEW_ROOM_TYPE,
    CHANGE_HOTEL_PASSWORD,
    CHECK_ROOM_AVAILABILITY,
    LIST_ALL_FACILITIES,
    LIST_ALL_HIGHLIGHTS,
    LIST_ALL_RATES,
    LIST_ALL_ROOM_TYPES,
    LIST_ROOMTYPE_DETAILS,
    UPDATE_BOOKING_ENGINE_VISIBILITY,
    UPDATE_ROOMTYPE,
    UPLOAD_FILE,
} from "../../graphql";
import {
    RESET_ALL_FACILITIES_DATA,
    RESET_ALL_HIGHLIGHTS_DATA,
    RESET_ALL_RATES,
    RESET_ALL_ROOM_TYPES_LIST,
    RESET_AVALABLE_ROOMS,
    RESET_ROOMTYPE_DETAILS,
    SAVE_ALL_FACILITIES,
    SAVE_ALL_HIGHLIGHTS,
    SAVE_ALL_RATES,
    SAVE_ALL_ROOM_TYPES_LIST,
    SAVE_AVALABLE_ROOMS,
    SAVE_ROOMTYPE_DETAILS,
    TOGGLE_LOADING,
} from "../types";
import { toast } from "react-toastify";

export const addNewHighlight = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: ADD_NEW_HIGHLIGHT,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            dispatch(resetAllHighlightsData());
            dispatch(listAllHighlights());
            return true;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const listAllHighlights = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        const {
            highlights: { pageSize, pageNumber, list },
        } = getState().admin;

        let apiResponse = await post("", {
            query: LIST_ALL_HIGHLIGHTS,
            variables: {
                pageSize: pageSize,
                pageNumber: pageNumber,
                ...requestData,
            },
        });

        if (apiResponse.data.customStatus) {
            let {
                list_all_highlights: { data, hasMore },
            } = apiResponse.data.data;

            let updatedList = [...list, ...data];

            dispatch({
                type: SAVE_ALL_HIGHLIGHTS,
                payload: {
                    list: updatedList,
                    showViewMore: hasMore,
                    pageNumber: pageNumber + 1,
                },
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetAllHighlightsData = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_ALL_HIGHLIGHTS_DATA });
    };
};

export const addNewFacility = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: ADD_NEW_FACILITY,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            dispatch(resetAllFacilitiesData());
            dispatch(listAllFacilities());
            return true;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const listAllFacilities = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        const {
            facilities: { pageSize, pageNumber, list },
        } = getState().admin;

        let apiResponse = await post("", {
            query: LIST_ALL_FACILITIES,
            variables: {
                pageSize: pageSize,
                pageNumber: pageNumber,
                ...requestData,
            },
        });

        if (apiResponse.data.customStatus) {
            let {
                list_all_facilities: { data, hasMore },
            } = apiResponse.data.data;

            let updatedList = [...list, ...data];

            dispatch({
                type: SAVE_ALL_FACILITIES,
                payload: {
                    list: updatedList,
                    showViewMore: hasMore,
                    pageNumber: pageNumber + 1,
                },
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetAllFacilitiesData = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_ALL_FACILITIES_DATA });
    };
};

export const checkRoomAvailability = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });
        const {
            availableRooms: { pageSize, pageNumber, list },
        } = getState().admin;

        let apiResponse = await post("", {
            query: CHECK_ROOM_AVAILABILITY,
            variables: {
                pageSize: pageSize,
                pageNumber: pageNumber,
                ...requestData,
            },
        });

        if (apiResponse.data.customStatus) {
            let {
                check_room_availability: { data, hasMore },
            } = apiResponse.data.data;
            let updatedList = [...list, ...data];
            dispatch({
                type: SAVE_AVALABLE_ROOMS,
                payload: {
                    list: updatedList,
                    showViewMore: hasMore,
                    pageNumber: 1,
                },
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetAvalableRoomsData = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_AVALABLE_ROOMS });
    };
};

export const addNewRate = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: ADD_NEW_RATE,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            dispatch(resetAllRatesData());
            dispatch(listAllRates());
            dispatch({ type: TOGGLE_LOADING, payload: false });
            return true;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const listAllRates = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });
        const {
            rates: { pageSize, pageNumber, list },
        } = getState().admin;

        let apiResponse = await post("", {
            query: LIST_ALL_RATES,
            variables: {
                pageSize: pageSize,
                pageNumber: pageNumber,
                ...requestData,
            },
        });

        if (apiResponse.data.customStatus) {
            let {
                list_all_rates: { data, hasMore },
            } = apiResponse.data.data;
            let updatedList = [...list, ...data];

            dispatch({
                type: SAVE_ALL_RATES,
                payload: {
                    list: updatedList,
                    showViewMore: hasMore,
                    pageNumber: 1,
                },
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetAllRatesData = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_ALL_RATES });
    };
};

export const addNewNewRoomType = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: ADD_NEW_ROOM_TYPE,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            dispatch(resetAllRoomTypesList());
            dispatch(listAllRoomTypes());
            return true;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const activateRoomtype = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: ACTIVATE_ROOMTYPE,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            dispatch(resetAllRoomTypesList());
            dispatch(listAllRoomTypes());
            return true;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const listAllRoomTypes = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });
        const {
            roomTypes: { pageSize, pageNumber },
        } = getState().admin;

        let apiResponse = await post("", {
            query: LIST_ALL_ROOM_TYPES,
            variables: {
                ...requestData,
                pageSize: requestData?.isForDropdown ? 200 : requestData?.pageSize || pageSize,
                pageNumber: requestData?.pageNumber || pageNumber,
            },
        });

        if (apiResponse.data.customStatus) {
            let {
                list_all_roomtypes: { data, hasMore, count },
            } = apiResponse.data.data;
            // let updatedList = [...list, ...data];
            // console.log(apiResponse);
            dispatch({
                type: SAVE_ALL_ROOM_TYPES_LIST,
                payload: {
                    list: data,
                    showViewMore: hasMore,
                    pageSize: requestData?.pageSize || pageSize,
                    pageNumber: requestData?.pageNumber || pageNumber,
                    count: count,
                },
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetAllRoomTypesList = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_ALL_ROOM_TYPES_LIST });
    };
};

export const updateRoomtype = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: UPDATE_ROOMTYPE,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            dispatch(resetRoomTypeDetails());
            dispatch(listRoomTypeDetails({id: requestData?.id}));
            return true;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const updateBookingEngineVisibility = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: UPDATE_BOOKING_ENGINE_VISIBILITY,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            dispatch(resetRoomTypeDetails());
            dispatch(listRoomTypeDetails({id: requestData?.id}));
            return true;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const listRoomTypeDetails = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: LIST_ROOMTYPE_DETAILS,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            let {
                list_roomtype_details: { data },
            } = apiResponse.data.data;
            dispatch({
                type: SAVE_ROOMTYPE_DETAILS,
                payload: data,
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetRoomTypeDetails = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_ROOMTYPE_DETAILS });
    };
};

export const uploadFile = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: UPLOAD_FILE,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            let {
                upload_file: { url },
            } = apiResponse.data.data;

            dispatch({ type: TOGGLE_LOADING, payload: false });
            return url;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const changeHotelPassword = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: CHANGE_HOTEL_PASSWORD,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            toast.success("Password updated");
            dispatch({ type: TOGGLE_LOADING, payload: false });
            return true;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};