export const CHECK_ROOM_AVAILABILITY = `query ($pageSize: Int, $pageNumber: Int, $checkinDate: Int!, $checkoutDate: Int!, $rooms: [check_room_availability_rooms]) {
  check_room_availability(page_size: $pageSize, page_number: $pageNumber, checkin_date: $checkinDate, checkout_date: $checkoutDate, rooms: $rooms){
    message
    data {
      room_number
      data {
        _id
        room_no
        no_of_beds
        rate
        images
        roomtype_id {
          _id
          name
          description
          is_private
        }
      }
      hasMore
    }
  }
}`;

export const ADD_NEW_BOOKING = `mutation ($name: String, $email: String, $checkinDate: Int!, $checkoutDate: Int!, $paymentMethod: String, $paidAmount: Float, $guestName: String, $guestEmail: String, $guestAddress: String, $guestMobile: String, $status: String, $agentId: String, $subAgentId: String, $remarks: String, $mealPlanId: String, $title: String, $customPrice: Float, $preference: String, $billingInstruction: String, $extraBeds: Int, $rooms:[add_new_booking_rooms], $customers: [add_new_booking_customers]) {
  add_new_booking(name: $name, email: $email, checkin_date: $checkinDate, checkout_date: $checkoutDate, payment_method: $paymentMethod, paid_amount: $paidAmount, guest_name: $guestName, guest_email: $guestEmail, guest_address: $guestAddress, guest_mobile: $guestMobile, status: $status, agent_id: $agentId, sub_agent_id: $subAgentId, remarks: $remarks, meal_plan_id: $mealPlanId, title: $title, custom_price: $customPrice, preference: $preference, billing_instruction: $billingInstruction, extra_beds: $extraBeds, rooms: $rooms, customers: $customers){
    message
    booking_id
  }
}
`;

export const LIST_ALL_BOOKINGS = `query ($pageSize: Int, $pageNumber: Int, $startDate: Int, $endDate: Int) {
  list_all_bookings(page_size: $pageSize, page_number: $pageNumber, start_date: $startDate, end_date: $endDate){
    message
    data {
      _id
      booking_id
      checkin_date
      checkout_date
      status
      total_price
      custom_price
      total_rooms
      paid_amount
      unpaid_amount
      booking_from
      guest_name
      guest_mobile
      guest_email
      roomtypes
    }
    hasMore
    count
  }
}
`;

export const FETCH_BOOKING_DETAILS = `query ($bookingId: String) {
    list_booking_details(booking_id: $bookingId){
      message
      data {
        _id
        booking_id
        checkin_date
        checkout_date
        status
        total_price
        custom_price
        total_rooms
        payment_method
        payment_status
        name
        email
        guest_mobile
        guest_phone
        guest_address
        title
        paid_amount
        unpaid_amount
        booking_from
        total_nights
        total_adults
        total_childs
        remarks
        type
        total_room_amount
        total_meals_amount
        preference
        billing_instruction
        booked_date
        createdAt
        updatedAt
        reference_id
        extra_beds
        meal_plan_id{
          name
          description
          rate
        }
        rooms {
          _id
          checkin_date
          checkout_date
          no_of_adults
          no_of_childs
          total_price
          adult_meals_price
          child_meals_price
          status
          dates
          room_id {
            _id
            room_no
            no_of_beds
            images
            roomtype_id {
              _id
              name
            }
          }
          meal_plan {
            plan
            name
            description
            rate
          }
        }
        customers {
          name
          email
          address
          sex
          country
          age
        }
        agent_id {
          _id
          first_name
          last_name
          email
          mobile
        }
        sub_agent_id {
          _id
          name
          email
          mobile
        }
      }
    }
  }`;

export const UPDATE_BOOKING_DETAILS = `mutation (
    $booking_id: String!,
    $checkin_date: Int,
    $checkout_date: Int,
    $name: String,
    $email: String,
    $payment_method: String,
    $paid_amount:Float,
    $guest_name: String,
    $guest_email: String,
    $guest_address: String,
    $guest_mobile: String,
    $status: String,
    $rooms: [update_booking_details_rooms]!
) {
  update_booking_details(
    booking_id: $booking_id,
    checkin_date: $checkin_date,
    checkout_date: $checkout_date,
    name: $name,
    email: $email,
    payment_method: $payment_method,
    paid_amount: $paid_amount,
    guest_name: $guest_name,
    guest_email: $guest_email,
    guest_address: $guest_address,
    guest_mobile: $guest_mobile,
    status: $status,
    rooms: $rooms
  ){
    message
  }
}`;

export const UPDATE_BOOKING_DATES = `mutation ($booking_id: String!, $checkin_date: Int!, $checkout_date: Int!) {
  update_booking_dates(booking_id: $booking_id, checkin_date: $checkin_date, checkout_date: $checkout_date){
    status
    message
    rooms {
      room_no
      booking_id
    }
  }
}`;

//unused
// export const CANCEL_BOOKING = `mutation ($booking_id: String!) {
//   cancel_booking(booking_id: $booking_id){
//     message
//   }
// }`;

export const UPDATE_CHECKIN_DETAILS = `mutation ($booking_id: String!, $customers: [update_checkin_details_customers]) {
  update_checkin_details(booking_id: $booking_id, customers: $customers){
    message
  }
}`;

export const ADD_NEW_GROUP_BOOKING = `mutation (
    $checkinDate: Int!,
    $checkoutDate: Int!,
    $numOfAdults: Int!,
    $numOfChilds: Int,
    $name: String,
    $email: String,
    $paymentMethod: String,
    $paidAmount: Float,
    $guestName: String,
    $guestEmail: String,
    $guestAddress: String,
    $guestMobile: String,
    $status: String,
    $agentId: String,
    $subAgentId: String,
    $remarks: String,
    $title: String,
    $customPrice: Float,
    $preference: String,
    $billingInstruction: String,
    $extraBeds: Int,
    $rooms: [add_new_group_booking_rooms]
) {
  add_new_group_booking(
    checkin_date: $checkinDate,
    checkout_date: $checkoutDate,
    no_of_adults: $numOfAdults,
    no_of_childs: $numOfChilds,
    name: $name,
    email: $email,
    payment_method: $paymentMethod,
    paid_amount: $paidAmount,
    guest_name: $guestName,
    guest_email: $guestEmail,
    guest_address: $guestAddress,
    guest_mobile: $guestMobile,
    status: $status,
    agent_id: $agentId,
    sub_agent_id: $subAgentId,
    remarks: $remarks,
    title: $title,
    custom_price: $customPrice,
    preference: $preference,
    billing_instruction: $billingInstruction,
    extra_beds: $extraBeds,
    rooms: $rooms
  ){
    message
    booking_id
  }
}`;

export const ADD_GROUP_BOOKING = `mutation ($name: String, $email: String, $payment_method: String, $paid_amount: Float, $guest_name: String, $guest_email: String, $guest_address: String, $guest_mobile: String, $status: String, $agent_id: String, $sub_agent_id: String, $remarks: String, $title: String, $custom_price: Float, $preference: String, $billingInstruction: String, $extra_beds: Int, $rooms: [add_group_booking_rooms]) {
  add_group_booking(name: $name, email: $email, payment_method: $payment_method, paid_amount: $paid_amount, guest_name: $guest_name, guest_email: $guest_email, guest_address: $guest_address, guest_mobile: $guest_mobile, status: $status, agent_id: $agent_id, sub_agent_id: $sub_agent_id, remarks: $remarks, title: $title, custom_price: $custom_price, preference: $preference, billing_instruction: $billingInstruction, extra_beds: $extra_beds, rooms: $rooms){
    message
    booking_id
  }
}`;

export const UPDATE_BOOKED_ROOM_STATUS = `mutation ($id: String!, $status: String!) {
  update_booked_room_status(_id: $id, status: $status){
    message
  }
}`;

export const UPDATE_BOOKED_ROOM_DETAILS = `mutation ($id: String!, $checkinDate: Int, $checkoutDate: Int) {
  update_booked_room_details(_id: $id,checkin_date: $checkinDate, checkout_date: $checkoutDate){
    message
  }
}`;

export const SWAP_BOOKED_ROOMS = `mutation ($id: String!, $room_id: String!) {
  swap_booked_rooms(_id: $id, room_id: $room_id){
    message
  }
}`;

export const UPDATE_BOOKING_DATAS = `mutation (
  $booking_id: String!,
  $name: String,
  $email: String,
  $custom_price: Float,
  $paid_amount: Float,
  $title: String,
  $guest_name: String,
  $guest_email: String,
  $guest_address: String,
  $guest_mobile: String,
  $agent_id: String,
  $sub_agent_id: String,
  $remarks: String,
  $preference: String,
  $billing_instruction: String,
  $notify_user: Boolean,
  $extra_beds: Int,
  $rooms: [update_booking_datas_rooms]
) {
  update_booking_datas(
    booking_id: $booking_id,
    name: $name,
    email: $email,
    custom_price: $custom_price,
    paid_amount: $paid_amount,
    title: $title,
    guest_name: $guest_name,
    guest_email: $guest_email,
    guest_address: $guest_address,
    guest_mobile: $guest_mobile,
    agent_id: $agent_id,
    sub_agent_id: $sub_agent_id,
    remarks: $remarks,
    preference: $preference,
    billing_instruction: $billing_instruction,
    notify_user: $notify_user,
    extra_beds: $extra_beds,
    rooms: $rooms
  ){
    message
  }
}`;

export const UPDATE_ALL_BOOKING_STATUS = `mutation ($bookingId: String!, $status: String!) {
  update_all_booking_status(booking_id: $bookingId, status: $status){
    message
  }
}`;

export const RESEND_BOOKING_CONFIRMATION = `query ($bookingId: String!, $mailto: [String], $isForUpdate: Boolean) {
  resend_booking_confirmation(booking_id: $bookingId, mailto: $mailto, is_for_update: $isForUpdate){
    message
  }
}`;

export const LIST_ALL_BOOKING_REPORTS = `query ($pageSize: Int, $pageNumber: Int, $startDate: Int, $endDate: Int, $type: String, $status: String, $bookingFrom: String) {
  list_all_booking_reports(page_size: $pageSize, page_number: $pageNumber, start_date: $startDate, end_date: $endDate, type: $type, status: $status, booking_from: $bookingFrom){
    message
    data {
      _id
      booking_id
      checkin_date
      checkout_date
      status
      total_price
      custom_price
      total_rooms
      total_nights
      paid_amount
      unpaid_amount
      booking_from
      type
      guest_name
      guest_mobile
      guest_email
      booked_at
      roomtypes
    }
    count
    total_amount
    total_nights
    total_confirmed_nights
    total_cancelled_nights
  }
}
`;